import {
  NETWORK_SUPPORTS_BRIDGE,
  NETWORK_SUPPORTS_FARMS,
  NETWORK_SUPPORTS_SINGLE_SIDE,
  NETWORK_SUPPORTS_YIELD_POOLS,
} from '@/helpers/supporting-features-in-network';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/layout/Layout.vue'),
    children: [
      {
        path: '/',
        redirect: '/swap',
      },
      {
        path: '/swap/:fromTokenSymbol?/:toTokenSymbol?',
        name: 'Swap',
        component: () => import('@/views/pages/swap/Swap.vue'),
      },
      {
        path: '/farming/:address?',
        name: 'Farming',
        component: () => {
          return NETWORK_SUPPORTS_FARMS
            ? import('@/views/pages/investment/farming/Farming.vue')
            : import('@/views/layout/error/FunctionalityNotSupported.vue');
        },
      },
      {
        path: '/staking',
        name: 'Staking',
        component: () => {
          return NETWORK_SUPPORTS_YIELD_POOLS
            ? import('@/views/pages/investment/staking/Staking.vue')
            : import('@/views/layout/error/FunctionalityNotSupported.vue');
        },
      },
      {
        path: '/portfolios/:address?',
        name: 'Portfolios',
        component: () => import('@/views/pages/liquidity/portfolios/Portfolios.vue'),
      },
      {
        path: '/single-side/:id?',
        name: 'SingleSide',
        component: () => {
          return NETWORK_SUPPORTS_SINGLE_SIDE
            ? import('@/views/pages/liquidity/single-side/SingleSide.vue')
            : import('@/views/layout/error/FunctionalityNotSupported.vue');
        },
      },
      {
        path: '/bridge',
        name: 'Bridge',
        component: () => {
          return NETWORK_SUPPORTS_BRIDGE
            ? import('@/views/pages/bridge/Bridge.vue')
            : import('@/views/layout/error/FunctionalityNotSupported.vue');
        },
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/layout/error/404.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
