import networks from '@/helpers/networks';
import { WRAPPED_MILKOMEDA_CARDANO_NETWORK_NAME } from './NETWORK_NAMES';
import { SUPPORTED_NETWORK_IDS, SupportedAppChainId } from './SUPPORTED_NETWORK_IDS';
import { ChainId } from '@/sdk/constants';
import { NetworkValue } from '@/constants/NETWORK_NAMES.types';

type CrossSiteSettings = {
  prefix: string;
};
export type Network = {
  name: string;
  value: NetworkValue;
  crossSite: CrossSiteSettings;
};
export const NETWORK_OPTION_BY_SUPPORTED_ID: Record<SupportedAppChainId, Network> = {
  // testnet
  fake200101: {
    name: WRAPPED_MILKOMEDA_CARDANO_NETWORK_NAME,
    value: 'Wrapped_Milkomeda_Cardano',
    crossSite: {
      prefix: '',
    },
  },
  [ChainId.MILKOMEDA_CARDANO_TESTNET]: {
    name: networks[ChainId.MILKOMEDA_CARDANO_TESTNET].name,
    value: 'Milkomeda_Cardano',
    crossSite: {
      prefix: 'MILKOMEDA_',
    },
  },
  [ChainId.KAVA_TESTNET]: {
    name: networks[ChainId.KAVA_TESTNET].name,
    value: 'Kava',
    crossSite: {
      prefix: '',
    },
  },
  [ChainId.POLYGON_TESTNET]: {
    name: networks[ChainId.POLYGON_TESTNET].name,
    value: 'Polygon',
    crossSite: {
      prefix: '',
    },
  },
  [ChainId.NEON_TESTNET]: {
    name: networks[ChainId.NEON_TESTNET].name,
    value: 'Neon',
    crossSite: {
      prefix: '',
    },
  },
  [ChainId.BERA_CHAIN_TESTNET]: {
    name: networks[ChainId.BERA_CHAIN_TESTNET].name,
    value: 'Bera_Chain',
    crossSite: {
      prefix: '',
    },
  },
  [ChainId.ETH_SEPOLIA_TESTNET]: {
    name: networks[ChainId.ETH_SEPOLIA_TESTNET].name,
    value: 'Eth_Sepolia',
    crossSite: {
      prefix: '',
    },
  },
  [ChainId.ARBITRUM_TESTNET]: {
    name: networks[ChainId.ARBITRUM_TESTNET].name,
    value: 'Arbitrum',
    crossSite: {
      prefix: '',
    },
  },
  [ChainId.BOB_CHAIN_TESTNET]: {
    name: networks[ChainId.BOB_CHAIN_TESTNET].name,
    value: 'Bob_Chain',
    crossSite: {
      prefix: '',
    },
  },
  // mainnet
  fake2001: {
    name: WRAPPED_MILKOMEDA_CARDANO_NETWORK_NAME,
    value: 'Wrapped_Milkomeda_Cardano',
    crossSite: {
      prefix: '',
    },
  },
  [ChainId.MILKOMEDA_CARDANO_MAINNET]: {
    name: networks[ChainId.MILKOMEDA_CARDANO_MAINNET].name,
    value: 'Milkomeda_Cardano',
    crossSite: {
      prefix: 'MILKOMEDA_',
    },
  },
  [ChainId.MILKOMEDA_ALGORAND_MAINNET]: {
    name: networks[ChainId.MILKOMEDA_ALGORAND_MAINNET].name,
    value: 'Milkomeda_Algorand',
    crossSite: {
      prefix: 'MILKOMEDA_',
    },
  },
  [ChainId.KAVA_MAINNET]: {
    name: networks[ChainId.KAVA_MAINNET].name,
    value: 'Kava',
    crossSite: {
      prefix: '',
    },
  },
  [ChainId.POLYGON_MAINNET]: {
    name: networks[ChainId.POLYGON_MAINNET].name,
    value: 'Polygon',
    crossSite: {
      prefix: '',
    },
  },
  [ChainId.BOB_CHAIN_MAINNET]: {
    name: networks[ChainId.BOB_CHAIN_MAINNET].name,
    value: 'Bob_Chain',
    crossSite: {
      prefix: '',
    },
  },
};

const options: Network[] = [];
SUPPORTED_NETWORK_IDS.forEach(chainId => {
  const option = NETWORK_OPTION_BY_SUPPORTED_ID[chainId];

  if (option) {
    options.push(option);
  }
});

export const NETWORK_OPTIONS: Readonly<Network[]> = options;
console.log('NETWORK_OPTIONS : ', NETWORK_OPTIONS);
