export const HIGH_RATES_LEARN_MORE =
  'https://blueshift-fi.gitbook.io/product-docs/product-concept/smart-minting-system';
export const AUTO_HODL_POOL_LEARN_MORE =
  'https://blueshift-fi.gitbook.io/product-docs/product-concept/yield-pools/auto-hodl-blues-pool';
export const SINGLE_SIDE_LEARN_MORE =
  'https://blueshift-fi.gitbook.io/product-docs/product-concept/portfolios/single-token-invest';
export const WSC_LEARN_MORE =
  'https://blueshift-fi.gitbook.io/product-docs/product-concept/cardano-wrapped-smart-contracts';

export const EXTERNAL_LINKS: { [key: string]: string } = {
  BLUESHIFT_ABOUT: 'https://blueshift.fi',
  BLUESHIFT_APP: 'https://app.blueshift.fi',
  WHITEPAPER: 'https://blueshift.fi/pdf/blueshift-whitepaper.pdf',
  CAREERS: 'https://angel.co/company/blueshiftfi',
  TELEGRAM_OFF_GROUP: 'https://t.me/blueshift',
  TELEGRAM_CHAT: 'https://t.me/BlueshiftGroup',
  GITBOOK: 'https://blueshift-fi.gitbook.io/product-docs/introduction/what-is-blueshift',
};

export const SOCIAL_LINKS: { [key: string]: string } = {
  TWITTER: 'https://twitter.com/blueshiftfi',
  TELEGRAM: 'https://t.me/BlueshiftGroup',
  MAIL: 'mailto:hello@blueshift.fi',
  GITHUB: 'https://github.com/blueshift-fi',
  DISCORD: 'https://t.co/zUNaFAd4o1',
};

export const ALGORAND_BRIDGE_MILKOMEDA = 'https://algorand-bridge.milkomeda.com/';

export const KAVA_GET_WKAVA = 'https://app.kava.io/evm/wkava';
export const POLIGON_GET_MATIC = 'https://faucet.polygon.technology/';

export const ANALYTICS_FRONTEND = {
  TESTNET: 'https://devinfo.blueshift.fi/',
  MAINNET: 'https://info.blueshift.fi/',
};

export const GITBOOK_LINKS = {
  PORTFOLIOS: 'https://blueshift-fi.gitbook.io/product-docs/product-concept/portfolios',
  SINGLE_SIDE:
    'https://blueshift-fi.gitbook.io/product-docs/product-concept/portfolios/single-token-invest',
  FARMING: 'https://blueshift-fi.gitbook.io/product-docs/product-concept/farming',
  STAKING: 'https://blueshift-fi.gitbook.io/product-docs/product-concept/yield-pools',
};

export const TERMS_OF_SERVICE = '/blueshift_terms_of_service.pdf';
export const PRIVACY_POLICY = '/blueshift_terms_of_service.pdf';

export const CHAIN_PORT_BRIDGE = 'https://app.chainport.io/?from=MILKOMEDA&to=BSC';

export const FAIR_LAUNCH_DOCS =
  'https://blueshift.fi/fairlaunch-meme-token-grant-program-preventing-rug-pulls/';
export const GYDDE_LINK = 'https://gydde.com/';
export const INTRACT_LINK =
  'https://www.intract.io/quest/664c57ba62867cdbffb8724a?campaign_initiator_type=alphaHubNarratives&campaign_initiator_id=664465cac1cc7f254473dcdd';
