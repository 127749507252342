import { ChainId } from '@/sdk/constants';
import networks from '@/helpers/networks';
import { ENABLE_CROSS_CHAIN_NETWORK_IDS } from './ENABLE_CROSS_CHAIN_NETWORK_IDS';
import {
  CHAIN_IDS_FOR_FAKE_CARDANO_NETWORK,
  ENABLE_FAKE_CARDANO_NETWORK,
} from '@/helpers/fakeCardanoNetwork';

type NetworkOptions = (typeof networks)[ChainId.MILKOMEDA_CARDANO_MAINNET];

function parseEnvToChainIds(env: string): ChainId[] {
  return env
    .split(',')
    .filter(id => !!id)
    .map(Number);
}

function parseEnvAndAddFakeCardanoToChainIds(env: string): ChainId[] {
  const chainIds = parseEnvToChainIds(env);

  if (
    !ENABLE_FAKE_CARDANO_NETWORK &&
    CHAIN_IDS_FOR_FAKE_CARDANO_NETWORK &&
    CHAIN_IDS_FOR_FAKE_CARDANO_NETWORK.length > 0
  ) {
    chainIds.push(...CHAIN_IDS_FOR_FAKE_CARDANO_NETWORK);
  }

  return chainIds;
}

export const CROSS_CHAIN_NETWORK_LIST_DROPDOWN_OPTIONS: NetworkOptions[] =
  ENABLE_CROSS_CHAIN_NETWORK_IDS.map(chainId => networks[chainId]);

export const NETWORKS_SUPPORTING_SINGLE_SIDE: readonly ChainId[] =
  parseEnvAndAddFakeCardanoToChainIds(process.env.VUE_APP_NETWORKS_SUPPORTING_SINGLE_SIDE ?? '');
console.log('NETWORKS_SUPPORTING_SINGLE_SIDE : ', NETWORKS_SUPPORTING_SINGLE_SIDE);

export const NETWORKS_SUPPORTING_BRIDGE: readonly ChainId[] = parseEnvAndAddFakeCardanoToChainIds(
  process.env.VUE_APP_NETWORKS_SUPPORTING_BRIDGE ?? '',
);
console.log('NETWORKS_SUPPORTING_BRIDGE : ', NETWORKS_SUPPORTING_BRIDGE);

export const NETWORKS_SUPPORTING_FARMS: readonly ChainId[] = parseEnvToChainIds(
  process.env.VUE_APP_NETWORKS_SUPPORTING_FARMS ?? '',
);
console.log('NETWORKS_SUPPORTING_FARMS : ', NETWORKS_SUPPORTING_FARMS);

export const NETWORKS_SUPPORTING_YIELD_POOLS: readonly ChainId[] = parseEnvToChainIds(
  process.env.VUE_APP_NETWORKS_SUPPORTING_YIELD_POOLS ?? '',
);
console.log('NETWORKS_SUPPORTING_YIELD_POOLS : ', NETWORKS_SUPPORTING_YIELD_POOLS);

export const NETWORKS_SUPPORTS_CHARTS: readonly ChainId[] = parseEnvToChainIds(
  process.env.VUE_APP_NETWORKS_SUPPORTS_CHARTS ?? '',
);
console.log('NETWORKS_SUPPORTS_CHARTS : ', NETWORKS_SUPPORTS_CHARTS);

export const NETWORKS_DISABLE_ADD_OPERATIONS: readonly ChainId[] = parseEnvToChainIds(
  process.env.VUE_APP_NETWORKS_DISABLE_ADD_OPERATIONS ?? '',
);
console.log('VUE_APP_NETWORKS_DISABLE_ADD_OPERATIONS : ', NETWORKS_DISABLE_ADD_OPERATIONS);
