import BigNumber from 'bignumber.js';

import { BIG_ZERO, ethersToBigNumber } from '@/utils/bigNumber';
import { Portfolio } from './portfolio';
import { FarmSourceType } from './farmSource';
import { BigintIsh, BLOCKS_PER_YEAR, DEFAULT_TOKEN_DECIMAL } from '../constants';
import { BOT_USD_DECIMALS } from '@/helpers/decimals-env';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { TokenAmount } from './fractions/tokenAmount';
import { Token } from './token';
import { LP_TOKEN_DECIMALS } from './constants/LP_TOKEN_DECIMALS';
import { fromWei } from '../utils';
import { useTokens } from '@/store/modules/tokens/useTokens';

const LOGGER = {
  groupCollapsed: (...label: any[]) => {
    if (isLoggingDisabled()) return;

    console.groupCollapsed(...label);
  },
  groupEnd: () => {
    if (isLoggingDisabled()) return;

    console.groupEnd();
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (isLoggingDisabled()) return;

    console.log(message, ...optionalParams);
  },
};

export class PortfolioFarm {
  public readonly multiplier: BigNumber;
  public readonly rewardPerBlock: BigNumber;
  public readonly accDeposited: BigNumber;
  public readonly deposited: BigNumber;
  public readonly earned: BigNumber;
  public readonly totalReward: BigNumber;
  public readonly portfolio: Portfolio;
  public readonly nativeTokenPriceInBase: BigNumber;
  public readonly priceInUSD: BigNumber;
  public readonly liquidityToken: Token;
  public readonly fee: BigNumber;
  public readonly tokensOwned: TokenAmount;
  public totalSupply: BigNumber;
  public readonly token: string;
  public readonly farm: string;
  public readonly tokenToMint: string;
  public readonly isSyrupPool: boolean;
  public readonly mintTokenPriceInBase: BigNumber;

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    statusFarms: object,
    portfolio: Portfolio,
    nativeTokenPriceInBase: BigNumber,
    fee: string,
    tokensOwned: BigintIsh,
    totalSupply: BigNumber,
    priceInUSD: BigNumber,
    mintTokenPriceInBase: BigNumber,
  ) {
    LOGGER.log('PortfolioFarm: statusFarms', statusFarms);
    this.multiplier = ethersToBigNumber(statusFarms['multiplier']);
    this.rewardPerBlock = ethersToBigNumber(statusFarms['rewardPerBlock']);
    this.accDeposited = ethersToBigNumber(statusFarms['accDeposited']);
    this.deposited = ethersToBigNumber(statusFarms['deposited']);
    this.earned = ethersToBigNumber(statusFarms['earned']);
    this.totalReward = ethersToBigNumber(statusFarms['totalReward']);
    this.portfolio = portfolio;
    this.token = statusFarms['token'];
    this.farm = statusFarms['farm'];
    this.nativeTokenPriceInBase = nativeTokenPriceInBase; // in USD
    this.mintTokenPriceInBase = mintTokenPriceInBase; // in USD
    this.priceInUSD = priceInUSD;
    LOGGER.log('priceInUsd', this.portfolio.lpTokenAddress, this.priceInUSD.toString());
    // LP token
    const LPToken = {
      chainId: this.portfolio.tokens[0].token.chainId,
      address: this.portfolio.lpTokenAddress,
      symbol: 'LQF-V2',
      decimals: LP_TOKEN_DECIMALS,
    };

    const { getTokenByAddressAndChainId, isPresentTokenIntoNetworkByAddress } = useTokens();
    if (
      ENABLE_FAKE_CARDANO_NETWORK &&
      isPresentTokenIntoNetworkByAddress(LPToken.address, LPToken.chainId)
    ) {
      const token = getTokenByAddressAndChainId(LPToken.address, LPToken.chainId);
      LPToken.symbol = token.symbol || LPToken.symbol;
      LPToken.decimals = token.decimals;
    }
    this.liquidityToken = new Token(
      LPToken.chainId,
      LPToken.address,
      LPToken.decimals,
      LPToken.symbol,
      'Liquifi V2',
    );
    // LP balance
    this.tokensOwned = new TokenAmount(this.liquidityToken, tokensOwned);
    this.totalSupply = totalSupply;
    this.fee = new BigNumber(fee);
    this.tokenToMint = statusFarms['tokenToMint'];
    this.isSyrupPool = statusFarms['isSyrupPool'];
  }

  public get sourceType(): FarmSourceType {
    return FarmSourceType.PORTFOLIO;
  }

  public get printDeposited(): TokenAmount {
    return new TokenAmount(
      this.liquidityToken,
      this.deposited.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
  }

  public get printDepositedUSD(): BigNumber {
    const lpPrice = this.lpTokenPrice().multipliedBy(this.deposited);
    const farmTotalValueBase = new TokenAmount(
      this.liquidityToken,
      lpPrice.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
    return this.getValueInUSD(farmTotalValueBase.toExact());
  }

  public get printLiquidity(): BigNumber {
    const lpPrice = this.lpTokenPrice().multipliedBy(this.accDeposited);
    LOGGER.log(
      `[LIQUIDITY | ${this.portfolio.name}] Liquidity in ${this.portfolio.baseToken.symbol} (LP decimals) :`,
      lpPrice.toString(),
    );
    const farmTotalValueBase = new TokenAmount(
      this.liquidityToken,
      lpPrice.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
    return this.getValueInUSD(farmTotalValueBase.toExact());
  }

  public get printTotalReward(): TokenAmount {
    return new TokenAmount(
      this.liquidityToken,
      this.totalReward.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
  }

  public get printEarned(): TokenAmount {
    return new TokenAmount(
      this.liquidityToken,
      this.earned.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
  }

  public get printEarnedUSD(): TokenAmount {
    const earned = this.earned.multipliedBy(this.nativeTokenPriceInBase);
    return new TokenAmount(
      this.liquidityToken,
      earned.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
  }

  public get printMultiplier(): string {
    return this.multiplier.toFixed();
  }

  public get printIPR(): number {
    const rewardPerBlock = this.rewardPerBlock.dividedBy(DEFAULT_TOKEN_DECIMAL);
    const fee = fromWei(this.fee, BOT_USD_DECIMALS);
    // NOTE: Changed `LP_TOKEN_DECIMALS` -> this.liquidityToken.decimals
    const accDeposited = fromWei(this.accDeposited, this.liquidityToken.decimals);
    const aprLeft = rewardPerBlock
      .multipliedBy(
        (this.isSyrupPool ? this.mintTokenPriceInBase : this.nativeTokenPriceInBase).multipliedBy(
          BLOCKS_PER_YEAR,
        ),
      )
      .plus(fee.multipliedBy(365).dividedBy(30));
    const aprRight = this.getValueInUSD(this.lpTokenPrice().toString()).multipliedBy(accDeposited);

    const ipr = aprLeft
      .dividedBy(aprRight)
      .multipliedBy(100)
      .integerValue(BigNumber.ROUND_DOWN)
      .toNumber();

    loggingIPR(this, ipr, rewardPerBlock, fee, accDeposited, aprLeft, aprRight);

    return ipr !== Infinity && !isNaN(ipr) ? ipr : 0;
  }

  // price: LP token in portfolio base token (from WEI)
  public lpTokenPrice(): BigNumber {
    const lpTP = this.portfolio.lpTokenPriceBase;
    return !lpTP.isNaN() ? lpTP : BIG_ZERO;
  }

  public calculateUSD(value: string): BigNumber {
    const lpPrice = this.lpTokenPrice().multipliedBy(value || BIG_ZERO);
    return this.getValueInUSD(lpPrice.toString());
  }

  public getValueInUSD(value: string, priceValue?: BigNumber): BigNumber {
    const valueInUSD = this.priceInUSD.multipliedBy(value);
    if (priceValue) {
      return valueInUSD.multipliedBy(priceValue);
    }
    return valueInUSD;
  }
}

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].FARMING;
}

function loggingIPR(
  portfolioFarm: PortfolioFarm,
  ipr: number,
  rewardPerBlock: BigNumber,
  fee: BigNumber,
  accDeposited: BigNumber,
  aprLeft: BigNumber,
  aprRight: BigNumber,
) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed(`[${portfolioFarm.portfolio.name}] IPR : `, ipr.toString());
  console.log('rewardPerBlock', rewardPerBlock.toString());
  console.log('nativeTokenPriceInBase', portfolioFarm.nativeTokenPriceInBase.toString());
  console.log('BLOCKS_PER_YEAR', BLOCKS_PER_YEAR);
  console.log(
    'nativeTokenPriceInBase.multipliedBy(BLOCKS_PER_YEAR)',
    portfolioFarm.nativeTokenPriceInBase.multipliedBy(BLOCKS_PER_YEAR).toString(),
  );
  console.log('fee', portfolioFarm.fee.toString(), fee.toString());
  console.log('aprLeft', aprLeft.toString());
  console.log('lpTokenPrice', portfolioFarm.lpTokenPrice().toString());
  console.log('priceInUSD', portfolioFarm.priceInUSD.toString());
  console.log(
    'lpTokenPrice in USD',
    portfolioFarm.getValueInUSD(portfolioFarm.lpTokenPrice().toString()).toString(),
  );
  console.log('accDeposited', portfolioFarm.accDeposited.toString(), accDeposited.toString());
  console.log('aprRight', aprRight.toString());
  console.groupEnd();
}
