import { ChainId } from '@/sdk/constants';
import { APP_NETWORK_NAME, DEFAULT_NETWORK_ID } from './networkParams.helper';
import { isWrappedNetwork } from './network-methods.helper';

export const CHAIN_IDS_FOR_FAKE_CARDANO_NETWORK: Readonly<ChainId[]> = (() => {
  const ids: ChainId[] = [];

  const fakeCardanoId = process.env.VUE_APP_NETWORK_FOR_FAKE_CARDANO_NETWORK;
  if (fakeCardanoId) {
    ids.push(Number(fakeCardanoId));
  }

  return ids;
})();

export const ENABLE_FAKE_CARDANO_NETWORK: Readonly<boolean> =
  isWrappedNetwork(APP_NETWORK_NAME) &&
  CHAIN_IDS_FOR_FAKE_CARDANO_NETWORK.includes(+(DEFAULT_NETWORK_ID ?? -1) as unknown as ChainId);
console.log('ENABLE_FAKE_CARDANO_NETWORK : ', ENABLE_FAKE_CARDANO_NETWORK);

export function isFakeCardanoChainId(chainId: string | ChainId): boolean {
  return (
    ENABLE_FAKE_CARDANO_NETWORK && CHAIN_IDS_FOR_FAKE_CARDANO_NETWORK.includes(+chainId as ChainId)
  );
}
