import {
  NETWORKS_DISABLE_ADD_OPERATIONS,
  NETWORKS_SUPPORTING_BRIDGE,
  NETWORKS_SUPPORTING_FARMS,
  NETWORKS_SUPPORTING_SINGLE_SIDE,
  NETWORKS_SUPPORTING_YIELD_POOLS,
  NETWORKS_SUPPORTS_CHARTS,
} from '@/constants/NETWORK_PARAMS';
import { DEFAULT_NETWORK_ID } from './networkParams.helper';

export const NETWORK_SUPPORTS_SINGLE_SIDE = NETWORKS_SUPPORTING_SINGLE_SIDE.includes(
  Number(DEFAULT_NETWORK_ID),
);

export const NETWORK_SUPPORTS_BRIDGE = NETWORKS_SUPPORTING_BRIDGE.includes(
  Number(DEFAULT_NETWORK_ID),
);

export const NETWORK_SUPPORTS_FARMS = NETWORKS_SUPPORTING_FARMS.includes(
  Number(DEFAULT_NETWORK_ID),
);

export const NETWORK_SUPPORTS_YIELD_POOLS = NETWORKS_SUPPORTING_YIELD_POOLS.includes(
  Number(DEFAULT_NETWORK_ID),
);

export const NETWORK_SUPPORTS_CHARTS = NETWORKS_SUPPORTS_CHARTS.includes(
  Number(DEFAULT_NETWORK_ID),
);

export const NETWORK_DISABLE_ADD_OPERATIONS = NETWORKS_DISABLE_ADD_OPERATIONS.includes(
  Number(DEFAULT_NETWORK_ID),
);

console.table({
  NETWORK_SUPPORTS_SINGLE_SIDE,
  NETWORK_SUPPORTS_BRIDGE,
  NETWORK_SUPPORTS_FARMS,
  NETWORK_SUPPORTS_YIELD_POOLS,
  NETWORK_SUPPORTS_CHARTS,
  NETWORK_DISABLE_ADD_OPERATIONS,
});
