import { CURRENT_SUPPORTED_NETWORK_IDS as RAW_CURRENT_SUPPORTED_NETWORK_IDS } from '@/helpers/networkParams.helper';
import { METAMASK_ERROR_CODES } from '@/constants/WALLET_PARAMS';
import { useLocalStorage } from '@/composables/useLocalStorage';

export const SUPPORTED_NETWORK_IDS = RAW_CURRENT_SUPPORTED_NETWORK_IDS?.split(',').map(Number);

export function isNetworkSupported(networkId: number): boolean {
  if (!SUPPORTED_NETWORK_IDS) {
    throw new Error('VUE_APP_<NETWORK>_CURRENT_SUPPORTED_NETWORK_IDS is not defined');
  }
  return SUPPORTED_NETWORK_IDS.includes(networkId);
}

// see https://docs.metamask.io/guide/rpc-api.html#wallet-addethereumchain
export interface AddEthereumChainParameter {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: number;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[]; // Currently ignored.
}

export function getLockConnectorName() {
  return useLocalStorage().getItem('_lock.connector');
}

export function getProviderByLockConnectorOrInjected() {
  return lookForProviderBy(getLockConnectorName()) ?? window['ethereum'];
}

export function lookForProviderBy(lockConnectorName) {
  const providers = {
    nufi: () => window['evmproviders']?.['nufi'] ?? window['ethereum'],
    injected: () => window['ethereum'],
  };

  return providers[lockConnectorName]?.();
}

export async function addNetwork(netParams: AddEthereumChainParameter) {
  try {
    await getProviderByLockConnectorOrInjected()?.request({
      method: 'wallet_addEthereumChain',
      params: [netParams],
    });
  } catch (addingError) {
    console.error('Adding new net failed', addingError);
  }
}

export async function switchNetwork(netParams: AddEthereumChainParameter): Promise<void> {
  try {
    await getProviderByLockConnectorOrInjected()?.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: netParams.chainId }],
    });
  } catch (error) {
    if (
      getLockConnectorName() === 'injected' &&
      [
        METAMASK_ERROR_CODES.CHAIN_WAS_NOT_ADDED,
        METAMASK_ERROR_CODES.UNRECOGNIZED_CHAIN_ID,
      ].includes(error.code)
    ) {
      addNetwork(netParams);
    } else {
      throw new Error(error.message);
    }
  }
}

export interface TokenOptions {
  address: string;
  symbol: string | undefined;
  decimals: number;
  image?: string;
}

export function addToken(tokenOptions: TokenOptions): void {
  getProviderByLockConnectorOrInjected()?.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: tokenOptions,
    },
  });
}
