import { PortfolioCrossChainDtoInterface } from '@/store/modules/portfolios/models/portfolio-crosschain-dto.interface';
import { BigNumber } from '@ethersproject/bignumber';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';
import { PortfolioDto } from '@/store/modules/portfolios/models/portfolio-dto.interface';
import { PortfolioTokenDto } from '@/store/modules/portfolios/models/portfolio-token-dto.interface';

export class CrossChainTokenInfo {}

export class PortfolioCrossChain {
  public type: PortfolioSource = PortfolioSource.PORTFOLIO_CROSSCHAIN;
  public portfolioId: string;
  public contractAddress: string;
  public lpTokenAddress: string;
  public lpTokenPrice: BigNumber;
  public lpTokenTotalSupply: BigNumber;
  public name: string;
  public tokenCount: string;
  // public tokens: CrossChainTokenInfo[] = [];
  public totalValue: BigNumber;

  public constructor(portfolioInfo: PortfolioCrossChainDtoInterface) {
    this.portfolioId = portfolioInfo.portfolioId;
    this.name = portfolioInfo.name;
    this.contractAddress = portfolioInfo.contractAddress;
    this.lpTokenAddress = portfolioInfo.lpTokenAddress;
    this.lpTokenPrice = portfolioInfo.lpTokenPrice;
    this.lpTokenTotalSupply = portfolioInfo.lpTokenTotalSupply;
    this.totalValue = portfolioInfo.totalValue;
    this.tokenCount = portfolioInfo.tokenCount;
  }

  public static createLocalPortfolioFromCrosschain(
    portfolioInfo: PortfolioCrossChainDtoInterface,
  ): PortfolioDto {
    const tokensInfo: PortfolioTokenDto[] = [];
    portfolioInfo.tokens.forEach(token => {
      // if (!token.isPresentLocally) return;

      tokensInfo.push({
        tokenAddress: token.tokenAddress,
        isPresentLocally: token.isPresentLocally,
        amount: BigNumber.from(token.amount),
        price: BigNumber.from(token.price),
        depositLimit: BigNumber.from(0),
        withdrawLimit: BigNumber.from(0),
        depositEMAPrice: BigNumber.from(token.depositEMAPrice),
        withdrawEMAPrice: BigNumber.from(token.withdrawEMAPrice),
        targetWeight: BigNumber.from(token.targetWeight),
        availableOn: token.availableOn,
      });
    });

    // const crossChainTokensInfo: PortfolioCrossChainTokenDtoInterface[] = [];
    // portfolioInfo.tokens.forEach(token => {
    //   if (token.isPresentLocally) return;
    //   crossChainTokensInfo.push({
    //     tokenAddress: token.tokenAddress,
    //     isPresentLocally: token.isPresentLocally,
    //     amount: BigNumber.from(token.amount),
    //     price: BigNumber.from(token.price),
    //     depositEMAPrice: BigNumber.from(token.depositEMAPrice),
    //     withdrawEMAPrice: BigNumber.from(token.withdrawEMAPrice),
    //     targetWeight: BigNumber.from(token.targetWeight),
    //     coinInfo: token.coinInfo,
    //   });
    // });
    return {
      name: portfolioInfo.name,
      portfolioId: +portfolioInfo.portfolioId,
      contractAddress: portfolioInfo.contractAddress,
      baseTokenAddress: portfolioInfo.baseTokenAddress,
      isBaseTokenPresentLocally: portfolioInfo?.isBaseTokenPresentLocally,
      lpTokenAddress: portfolioInfo.lpTokenAddress,
      lpTokenPrice: BigNumber.from(portfolioInfo.lpTokenPrice),
      totalValue: BigNumber.from(portfolioInfo.totalValue),
      tokenCount: BigNumber.from(portfolioInfo.tokenCount),
      tokens: tokensInfo,
      crossChainTokens: [],
      isStableswap: false,
    };
  }
}
