import defaultConnectors from '@/helpers/connectors/wallets/connectors.json';
import fakeCardanoConnectors from '@/helpers/connectors/wallets/fake-cardano-connectors.json';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';

export type WalletConnector = Partial<(typeof defaultConnectors)['injected']>;
type Connectors = Record<string, WalletConnector>;

export const CONNECTORS: Readonly<Connectors> = (() => {
  // NOTE
  // NuFi was removed from connectors.json.
  // This is only remove NuFi from the list of wallets.
  // TODO: Need clean up code from NuFi supporting.
  let connectors: Record<string, WalletConnector> = defaultConnectors;

  if (ENABLE_FAKE_CARDANO_NETWORK) {
    // NOTE
    // NuFi was removed from fake-cardano-connectors.json.
    // This is only remove NuFi from the list of wallets for Cardano.
    // TODO: Need clean up code from NuFi supporting.
    connectors = fakeCardanoConnectors;
  }

  return connectors;
})();

export const WALLET_LIST: Readonly<WalletConnector[]> = (() => {
  const wallets: WalletConnector[] = [];
  Object.keys(CONNECTORS).forEach(item => {
    wallets.push(CONNECTORS[item]);
  });
  return wallets;
})();
