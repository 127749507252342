import { ANALYTIC_BACKEND_URL } from '@/helpers/networkParams.helper';

type TotalSummaryResponse = {
  id: string;
  bluesPrice: number;
  bluesPrice24hChange: number;
  bluesTotalSupply: number;
  volume24hUSD: number;
  volume24hChange: number;
  fees24hUSD: number;
  fees24hChange: number;
  tvlUSD: number;
  tvl24hChange: number;
  txCount24h: number;
  txCount24hChange: number;
  dateTime: string;
  roundedTimestamp: number;
};

export async function getTotalSummary(): Promise<TotalSummaryResponse> {
  const response = await fetch(`${ANALYTIC_BACKEND_URL}api/statistic/total-summary`, {
    method: 'post',
    headers: { 'content-type': 'application/json' },
  });
  const data = (await response.json()) as Promise<TotalSummaryResponse[]>;

  return data?.[0];
}

export async function fetchSpicePointsBalance(walletAddress: string): Promise<number> {
  const queryParams = new URLSearchParams();
  queryParams.append('walletAddress', walletAddress);

  const response = await fetch(
    `${ANALYTIC_BACKEND_URL}api/marketing/award/spice-points?${queryParams.toString()}`,
    {
      method: 'get',
      headers: { 'content-type': 'application/json' },
    },
  );

  const json = await response.json();

  if (json.error) throw json.error;

  return json as Promise<number>;
}
