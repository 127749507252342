import { useLocalStorage } from '@/composables/useLocalStorage';
import { SUPPORTED_NETWORK_MODE } from '@/constants/SUPPORTED_NETWORK_MODE';
import networks from '@/helpers/networks';
import { getURLParam } from '@/helpers/appParams.helper';
import { AddEthereumChainParameter } from '@/utils/blockchain';
import { ChainId } from '@/sdk/constants';
import { NETWORK_VALUE_BY_WRAPPED_NETWORK_VALUE } from '@/constants/NETWORK_NAMES';
import { NetworkValue } from '@/constants/NETWORK_NAMES.types';
import { NETWORK_OPTIONS } from '@/constants/NETWORK_OPTIONS';
import { ANALYTICS_FRONTEND } from '@/constants/EXTERNAL_LINKS';
import { DEFAULT_NETWORK_VALUE } from '@/constants/DEFAULT_NETWORK_VALUE';

export const getNetworkId = netName => {
  return process.env['VUE_APP_' + netName.toUpperCase() + '_NETWORK_ID'];
};

export const getNetworkParams = (netId): AddEthereumChainParameter => {
  const net = networks[netId];
  const chainId = +net.key;
  const chainIdHec = '0x' + chainId.toString(16);

  return {
    chainId: chainIdHec,
    rpcUrls: net.rpc,
    chainName: net.shortName,
    nativeCurrency: net.nativeCurrency,
    blockExplorerUrls: [net.explorer],
    iconUrls: [net.iconLink],
  };
};

const normalizeNetworkValue = (value: string) => value.toLowerCase();

const normalizeNetworkOptions = () => {
  const normalizeToOriginNetwork = {};
  const normalizeSupportedNetsShortNames: string[] = [];
  NETWORK_OPTIONS.forEach(item => {
    const normalize = normalizeNetworkValue(item.value);
    const origin = item.value;
    normalizeToOriginNetwork[normalize] = origin;
    normalizeSupportedNetsShortNames.push(normalize);
  });

  return {
    normalizeToOriginNetwork,
    normalizeSupportedNetsShortNames,
  };
};

export const getNetworkURLParam = () => {
  const { normalizeToOriginNetwork, normalizeSupportedNetsShortNames } = normalizeNetworkOptions();

  const normalizeNetURLParam = <NetworkValue>normalizeNetworkValue(getURLParam('network'));
  const isURLNetSupported = normalizeSupportedNetsShortNames.includes(normalizeNetURLParam);

  return isURLNetSupported ? normalizeToOriginNetwork[normalizeNetURLParam] : '';
};

/**
 * Network value gets from 'network' URL param, local storage or default value.
 * Network which user select for app.
 */
export const APP_NETWORK_NAME: NetworkValue = String(
  getNetworkURLParam() ||
    useLocalStorage().getItem<string>(`network_${SUPPORTED_NETWORK_MODE}`) ||
    DEFAULT_NETWORK_VALUE,
) as NetworkValue;
/**
 * Unwrapped network value.
 */
export const SELECTED_NETWORK_NAME = NETWORK_VALUE_BY_WRAPPED_NETWORK_VALUE[APP_NETWORK_NAME];

// FIX:
// When localstorage contains incorrect network: remove item and reload page.
if (APP_NETWORK_NAME && !SELECTED_NETWORK_NAME) {
  cleanLocalStorageAndReloadApp();
}

export const DEFAULT_NETWORK_ID = getNetworkId(SELECTED_NETWORK_NAME);
// FIX:
// When localstorage contains incorrect network: remove item and reload page.
if (!DEFAULT_NETWORK_ID) {
  cleanLocalStorageAndReloadApp();
}

export const FULL_SELECTED_NETWORK_NAME = networks[Number(DEFAULT_NETWORK_ID)].name;

export const CURRENT_SUPPORTED_NETWORK_IDS =
  process.env['VUE_APP_' + SELECTED_NETWORK_NAME.toUpperCase() + '_CURRENT_SUPPORTED_NETWORK_IDS'];

export const NETWORK_BACKEND_URL =
  process.env['VUE_APP_' + SELECTED_NETWORK_NAME.toUpperCase() + '_BACKEND_URL'];

export const NETWORK_ENGINE =
  process.env['VUE_APP_' + SELECTED_NETWORK_NAME.toUpperCase() + '_ENGINE_NETWORK'];

export const NETWORK_USD_TOKEN_ADDRESS =
  process.env['VUE_APP_' + SELECTED_NETWORK_NAME.toUpperCase() + '_USD_TOKEN_ADDRESS'];

export const BLUES_CROSSCHAIN_URL = process.env.VUE_APP_BLUES_CROSSCHAIN_URL;

export const BLUES_CROSSCHAIN_TRANSACTION_STATUS_RETRY_DELAY_IN_SEC =
  Number(process.env.VUE_APP_BLUES_CROSSCHAIN_TRANSACTION_STATUS_RETRY_DELAY_IN_SEC) || 5;

export const ANALYTIC_BACKEND_URL = process.env.VUE_APP_ANALYTIC_BACKEND_URL;

export const ANALYTIC_FRONTEND_URL = `${ANALYTICS_FRONTEND[SUPPORTED_NETWORK_MODE]}#/`;

export const getChainNameById = (id: ChainId): string => {
  const chainName = networks[id]?.name;
  if (!chainName) {
    throw new Error(`Blockchain ${id} was not found.`);
  }
  return chainName;
};

function cleanLocalStorageAndReloadApp() {
  console.warn('[BLUSHIFT:LAUNCH] BLUESHIFT App can not launched.');

  useLocalStorage().removeItem(`network_${SUPPORTED_NETWORK_MODE}`);
  window.location.reload();

  throw new Error(`[BLUSHIFT:LAUNCH] BLUESHIFT App can not launched. BLUESHIFT App will reload.`);
}
